.main-wrapper {
  height: 100vh;
  width: 100%;
  overflow-y: hidden;
}

.background::-webkit-scrollbar {
  display: none;
}

.background {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.background {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 99999;
  overflow-y: auto;
  backdrop-filter: blur(10px);
  padding: 40px 0px;
}

.container {
  padding: 65px 60px 18px 60px;
  background-color: var(--card-color);
  border-radius: 20px;
  width: 512px;
  position: relative;
  z-index: 1;
  margin: auto;
  height: fit-content;
}

.title {
  font-size: 40px;
  color: var(--white-color);
  margin-bottom: 30px;
}

.input-box {
  margin-bottom: 10px;
}

.input-label {
  color: #bbbbbb;
  font-size: 14px;
  margin-bottom: 6px;
}

.input {
  border: none;
  outline: none;
  background-color: var(--bg-color);
  padding: 20px 25px;
  border-radius: 10px;
  width: 100%;
  height: 65px;

  /* text */
  font-size: 18px;
  color: var(--white-color);
  font-weight: 600;
}

.input:focus {
  background-color: var(--bg-color);
  border: 2px solid var(--primary-color);
  box-shadow: 0 0 5px rgba(255, 202, 29, 0.5);
}

.input::placeholder {
  color: #4d4d4d;
  font-size: 18px;
  font-weight: 600;
}

.btn {
  border: none;
  outline: none;
  background-color: var(--primary-color);
  height: 65px;
  width: 100%;
  border-radius: 10px;
  margin: 20px 0px 25px 0px;
  cursor: pointer;

  /* text */
  font-size: 18px;
  font-weight: 600;
  color: var(--white-color);
}

.btn:active {
  transform: scale(0.98);
}

.btn:disabled {
  background-color: #a5a5a5; /* Gray color indicating disabled state */
  cursor: not-allowed;
  color: #d9d9d9; /* Light gray text for disabled state */
}

.btn:disabled:active {
  transform: none;
}

.reminder-text {
  font-size: 18px;
  color: #4d4d4d;
  text-align: center;
}

.reminder-text a {
  color: var(--white-color);
}

.descr {
  font-size: 13px;
  color: #666666;
  text-align: center;
  margin-top: 10px;
}

.descr span {
  color: var(--white-color);
}

.password-container {
  position: relative;
  width: 100%;
}

.eye-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: white;
  font-size: 22px;
}

.password-input {
  padding-right: 60px;
}

@media (max-width: 900px) {
  .container {
    width: 420px;
    padding: 50px 45px 15px 45px;
  }

  .title {
    font-size: 35px;
  }

  .input {
    height: 55px;
    padding: 15px 20px;
    font-size: 17px;
  }

  .btn {
    height: 55px;
  }
}

@media (max-width: 600px) {
  .container {
    width: 380px;
    padding: 40px 35px 10px 35px;
  }

  .title {
    font-size: 30px;
  }

  .input {
    height: 50px;
    padding: 12px 18px;
    font-size: 16px;
  }

  .btn {
    height: 50px;
    font-size: 16px;
    margin: 15px 0px 20px 0px;
  }

  .reminder-text {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .container {
    margin: auto 20px;
    padding: 30px 30px 15px 30px;
  }

  .title {
    font-size: 27px;
    margin-bottom: 20px;
  }
}
